/* @font-face {
  font-family: 'ABBvoice_RG';
  src: local('ABBvoice_RG'), url(./fonts/ABBvoice_W_Rg.woff) format('woff');

} */
@font-face {
  font-family: "ABBVoice";
  src: url("https://www07.abb.com/cdn/Fonts/abbvoice/ABBvoice_W_Rg.eot");
  src: url("https://www07.abb.com/cdn/Fonts/abbvoice/ABBvoice_W_Rg.eot?#iefix")
      format("embedded-opentype"),
    url("https://www07.abb.com/cdn/Fonts/abbvoice/ABBvoice_W_Rg.woff2")
      format("woff2"),
    url("https://www07.abb.com/cdn/Fonts/abbvoice/ABBvoice_W_Rg.woff")
      format("woff");
}

@font-face {
  font-family: "Oppo Sans";
  src: url("./fonts/OPPOSans3.0-Regular.ttf");
}

body {
  margin: auto;
  /* max-width: 1800px; */
}

.App {
  /* text-align: center; */
  /* color: rgb(64, 132, 243); */
  outline: "none";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  /* align-items: center;
  justify-content: center; */
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

.MuiInput-underline::before,
.MuiInput-underline::after {
  border-bottom: none !important;
}

.MuiSelect-select:focus {
  /* background-color: #fff !important; */
  background-color: transparent !important;
}

.MuiSelect-icon {
  /* color: #ed5f9e !important; */
  /* right: 20px !important; */
  font-size: 32px !important;
  top: calc(50% - 16px) !important;
}

#search-input::placeholder {
  /* opacity: 1 */
}

/* carousel */

/* .carousel {
  max-height: 456px;
} */

@media screen and (max-width: 860px) {
  .carousel {
    max-height: 256px;
  }
}

.featuredCarousel .carousel-root .slider-wrapper .slider {
  margin-top: 35px;
  margin-bottom: 55px;
}

.featuredCarousel {
  padding: 0px;
}

.control-arrow {
  bottom: 0 !important;
  top: auto !important;
  opacity: 1 !important;
  background-color: rgba(139, 139, 139, 0.8) !important;
  padding: 6px !important;
  border-radius: 10px;
}
/* .carousel .carousel-dots {
  bottom: 15px !important;
}
.featuredCarousel .carousel .carousel-dots {
  bottom: -30px !important;
}

.carousel .carousel-dots button {
  font-size: 75px !important;
}

.carousel .carousel-left-arrow {
  left: 75px !important;
}
.carousel .carousel-left-arrow {
  left: 75px !important;
}

.featuredCarousel .carousel .carousel-left-arrow {
  left: -25px !important;
}
.featuredCarousel .carousel .carousel-right-arrow {
  right: -25px !important;
}

.featuredProd > .carousel .carousel-left-arrow {
  left: -15px !important;
}

.featuredProd > .carousel .carousel-right-arrow {
  right: -15px !important;
} */

/* .carousel .carousel-arrow {
  cursor: pointer;
  text-align: center;
  padding: 15px !important;
  border-radius: 60%;
  color: white;
  background-color: rgba(0, 0, 0, 0.45) !important;
  opacity: 0.45; 
  bottom: 300px !important;
  bottom: 215px !important;

  background-color: transparent;
} */

.arrow-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 55px;
  height: 55px;
  border-radius: 50%;
  /* background-color: rgba(255, 255, 255, 0.9); */
}

.arrow-container-product {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: rgb(50, 50, 50);
}

#left-caret-product {
  width: 12px;
}

#right-caret-product {
  width: 12px;
}

#details-image .carousel .carousel-left-arrow {
  left: 10px !important;
}

#details-image .carousel .carousel-right-arrow {
  right: 10px !important;
}

#left-caret {
  padding-right: 5px;
}

#right-caret {
  padding-left: 5px;
}

.carousel .carousel-track .carousel-slide > *,
.carousel .carousel-container-inner {
  max-height: 658px;
}

@media screen and (max-width: 860px) {
  .carousel .carousel-dots {
    /* bottom: 15px !important; */
  }

  .carousel .carousel-dots button {
    font-size: 40px !important;
  }

  .carousel .carousel-left-arrow {
    left: 0px !important;
  }

  .carousel .carousel-right-arrow {
    right: 0px !important;
  }

  .carousel .carousel-arrow {
    bottom: 128px !important;
  }

  .arrow-container {
    width: 40px;
    height: 40px;
  }

  .arrow-container-product {
    width: 25px;
    height: 25px;
  }

  #left-caret-product {
    width: 8px;
  }

  #right-caret-product {
    width: 8px;
  }
}

/* quill editor */

.ql-editor {
  font-size: 18px;
  padding-left: 0px !important;
}

/* mat ui */

.MuiPaper-elevation2 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0),
    0px 3px 4px 0px rgba(0, 0, 0, 0), 0px 1px 8px 0px rgba(0, 0, 0, 0.11) !important;
}

.MuiSelect-select.MuiSelect-select {
  padding-left: 6px !important;
  margin-right: 18px;
  margin-left: 14px;

  /* padding-left: 24px !important; */
  background-color: transparent;
  border-radius: 16px;
}

a:link,
a:visited {
  /* color: rgb(64, 132, 243) !important; */
  text-decoration: none;
}

/* half background */
#account-background {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
}

#account-background:before {
  position: absolute;
  z-index: -1;
  /* top: 0;
  left: 0; */
  width: 100%;
  height: 40%;
  content: "";
  /* background-color: #2f6eb5; */
}

#custom-search-input-one,
#custom-search-input-two,
#custom-search-input-three,
#multiple-checkbox-status,
#multiple-checkbox-branch,
#multiple-checkbox-claim,
#multiple-checkbox-role,
#multiple-checkbox-assigned-to,
#multiple-checkbox-order {
  padding: 4px;
  padding-left: 4px;
  background-color: #fff !important;
  border-radius: 0px !important;
  /* font-size: 12px; */
  /* min-width: 150px; */
  height: 20px;
  text-align: left;
  /* max-width: 150px; */
  width: 120px;
}

#multiple-checkbox-status,
#multiple-checkbox-branch,
#multiple-checkbox-claim,
#multiple-checkbox-role,
#multiple-checkbox-assigned-to,
#multiple-checkbox-order {
  min-width: 150px;
}

#multiple-checkbox-status,
#multiple-checkbox-branch,
#multiple-checkbox-claim,
#multiple-checkbox-role,
#multiple-checkbox-assigned-to,
#multiple-checkbox-order {
  margin-left: 0;
  margin-right: 0;
}

#multiple-checkbox-status-parent > div > .MuiSelect-icon,
#multiple-checkbox-branch-parent > div > .MuiSelect-icon,
#multiple-checkbox-role-parent > div > .MuiSelect-icon,
#multiple-checkbox-assigned-to-parent > div > .MuiSelect-icon,
#multiple-checkbox-order-parent > div > .MuiSelect-icon,
#multiple-checkbox-claim-parent > div > .MuiSelect-icon,
.MuiTablePagination-selectRoot > .MuiSelect-icon {
  color: rgba(0, 0, 0, 0.54) !important;
}

.MuiCardMedia-img {
  object-fit: contain !important;
}

#mobile-cc {
  /* padding-bottom: 0 !important; */
}

#cat-card-img {
  object-fit: cover !important;
}

/* datepicker */

#date-picker-dialog-label {
  z-index: 1000;
  padding-left: 5px;
}

#date-picker-dialog-label + div {
  background-color: #fff;
  padding-left: 5px;
}

input[type="number"]::-webkit-inner-spin-button {
  width: 1.5rem;
  height: 2rem;
}
input[type="number"]:hover::-webkit-inner-spin-button {
  width: 1.5rem;
  height: 2rem;
}

.quantity > textarea:focus,
input:focus {
  outline: none;
}

/* spinner/processing state, errors */
.spinner,
.spinner:before,
.spinner:after {
  border-radius: 50%;
}

.spinner {
  color: #ffffff;
  font-size: 22px;
  text-indent: -99999px;
  margin: 0px auto;
  position: relative;
  width: 20px;
  height: 20px;
  box-shadow: inset 0 0 0 2px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
}

.spinner:before,
.spinner:after {
  position: absolute;
  content: "";
}

.spinner:before {
  width: 10.4px;
  height: 20.4px;
  background: #5469d4;
  border-radius: 20.4px 0 0 20.4px;
  top: -0.2px;
  left: -0.2px;
  -webkit-transform-origin: 10.4px 10.2px;
  transform-origin: 10.4px 10.2px;
  -webkit-animation: loading 2s infinite ease 1.5s;
  animation: loading 2s infinite ease 1.5s;
}

.spinner:after {
  width: 10.4px;
  height: 10.2px;
  background: #5469d4;
  border-radius: 0 10.2px 10.2px 0;
  top: -0.1px;
  left: 10.2px;
  -webkit-transform-origin: 0px 10.2px;
  transform-origin: 0px 10.2px;
  -webkit-animation: loading 2s infinite ease;
  animation: loading 2s infinite ease;
}

@keyframes loading {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.PhoneInputInput {
  border: none !important;
}

.ql-snow
  .ql-picker.ql-merge-tags
  .ql-picker-item[data-value]:not([data-value=""])::before {
  content: attr(data-value);
}
