#footer {
  /* background: rgb(64, 132, 243); */
  /* color: white; */
  font-size: 11px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

#footer-inner {
  /* width: 1080px; */
  flex-grow: 1;
  font-weight: bold;
  display: flex;
  justify-content: center;
}

#copyright {
  /* background-color: rgb(51, 112, 179); */
  font-size: 11px;
  padding: 5px;
  text-align: start;
  box-sizing: border-box;
  width: 100%;
}

/* from demo */

#block-pshop_core-small_logo {
  margin-left: 2rem;
  margin-right: 2rem;
}

div.block {
  position: relative;
}

.block-footer_block {
  float: left;
  min-width: 160px;
  margin-top: 30px;
  margin-bottom: 20px;
}

.block ul {
  margin: 0;
  padding: 0 0 0.25em 1em;
}

.clearfix {
  display: block;
}

.block-footer_block {
  float: left;
  min-width: 160px;
  margin-top: 30px;
  margin-bottom: 20px;
}

#footer li a:link,
#footer li a:visited {
  /* color: white !important; */
}
#block-menu-secondary-links li a {
  /* display: inline; */
  overflow: hidden;
  /* border-right: 1px solid white; */
  font-size: 11px;
  font-weight: bold;
  /* text-align: center; */
  text-align: start;
  text-decoration: none;
  /* padding: 2px 10px; */
}
.block-menu li a {
  display: block;
  margin: 0;
}
a:link,
a:visited {
  /* color: #404040; */
  text-decoration: none;
}

.block-menu h3 {
  display: none;
}

.block-menu ul.menu,
.block-menu ul.menu li {
  list-style: none;
  margin: 0;
  padding: 0;
}

#footer li {
  list-style: none;
  margin: 0;
  padding: 2px 10px;
}

.clearfix::after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

ul {
  list-style-type: disc;
}

#footer li a:link,
#footer li a:visited {
  /* color: white !important; */
}

#block-pshop_core-quick-contacts a {
  margin-right: 15px;
}

#block-pshop_core-quick-contacts img {
  height: 20px;
  margin-top: 5px;
}
img {
  border: 0;
}

#block-menu-primary-links {
  margin-left: 25px;
}

#block-menu-primary-links li a {
  /* color: white;
  text-align: center;
  text-decoration: none;
  display: block;
  border: 1px solid white;
  border-radius: 5px;
  padding: 0 30px; */
}

#block-menu-primary-links li a::after {
  /* content: ' >';
  color: #ea5e9d; */
}

#block-menu-primary-links li a {
  /* color: white; */
  text-align: center;
}

#block-pshop_core-syd-address {
  display: none;
}

@media screen and (max-width: 1080px) {
  #footer-inner {
    width: 100%;
    flex-grow: 1;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-flow: column wrap; */
    flex-flow: row wrap;
    /* flex-direction: column; */
  }
}
